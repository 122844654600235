import React, { useEffect, useState } from "react";
import axios from "axios";
import "./app.css"; // Import your CSS file

const App = () => {
  const [banners, setBanners] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api_url = "https://api2.fly247.in/api/v1/api"; // Replace with your API URL
        const bannersResponse = await axios.get(`${api_url}/getBanners`);
        setBanners(bannersResponse.data);

        const companiesResponse = await axios.get(`${api_url}/getCompanies`);
        setCompanies(companiesResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const carouselSlide = document.querySelector(".carousel-slide");
    let counter = 0;

    const moveCarousel = () => {
      if (carouselSlide) {
        const size = carouselSlide.children[counter].clientWidth;
        carouselSlide.style.transition = "transform 0.5s ease-in-out";
        counter++;
        if (counter >= carouselSlide.children.length) {
          counter = 0;
        }
        carouselSlide.style.transform = `translateX(${-size * counter}px)`;
      }
    };

    const intervalId = setInterval(moveCarousel, 3000);

    return () => clearInterval(intervalId);
  }, [banners]);

  return (
    <div>
      <header>
        <h1>Gauri Book</h1>
        <div className="carousel">
          <div className="carousel-slide">
            {banners.map((banner, index) => (
              <img
                key={index}
                src={`https://api2.fly247.in/${banner.filePath}`}
                alt="Banner"
              />
            ))}
          </div>
        </div>
      </header>
      <main>
        <section className="available-sites">
          <h2>Available Sites</h2>
          <div className="sites">
            {companies.map((company, index) => (
              <a
                key={index}
                className="site"
                href={`https://wa.me/+91${company.whatsapp}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`https://api2.fly247.in/${company.iconPath}`}
                  alt={company.name}
                />
                <span>{company.name}</span>
              </a>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default App;
